<template>
    <div class="min-h-screen flex-1 mt-1 bg-gray-100 px-4 py-3 items-center">
        <div class="bg-white w-full rounded-lg shadow pb-20">
            <div class="h-20 py-3 flex items-center border-gray-200 p-6 mt-5">
                <div class="flex w-full">
                   <button
                        :class="$colors.primary"
                        class="rounded-full flex justify-center items-center w-8 h-8 mr-3"
                        @click="RouterBack()"
                    >
                        <i class="material-icons">chevron_left</i>
                    </button>
                    <div class="text-xl font-bold text-green-600">Sourcing details</div>
                </div>
            </div>
            <div id="detail">
                <div class="flex pr-5 pl-5 space-x-3">
                    <div class="flex justify-between shadow w-full h-auto p-4 my-1 rounded-lg border-gray-100">
                        <div class="flex w-full">
                            <span class="rounded-full h-12 w-12 bg-gray-100">
                                <i class="material-icons p-3 text-green-400">list_alt</i>
                            </span>
                            <div class="ml-5 w-full space-y-2">
                                <div class="text-base mt-2 mb-2 font-semibold text-gray-700">Detail</div>
                                <div class="text-sm text-gray-600">
                                   Seller Fullname:
                                    <span class="justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700">
                                        {{ formData.seller.fullName }}
                                    </span>
                                </div>
                                <div class="text-sm text-gray-600">
                                    Pays D'achat :
                                    <span
                                        class="justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700">
                                        {{ formData.countryName }}
                                    </span>
                                </div>
                               
                                <div v-if="formData.facturationDate" class="text-sm text-gray-600">
                                    Facturation Date :
                                    <span
                                        class="justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700">
                                        {{ $moment(this.formData.facturationDate).format('yyyy-MM-DD') }} 
                                    </span>
                                </div>
                                <div  v-if="formData.validationDate" class="text-sm text-gray-600">
                                    Validation Date :
                                    <span
                                        class="justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700">
                                        {{ $moment(this.formData.validationDate).format('yyyy-MM-DD') }} 
                                    </span>
                                </div>
        
                                
                                <div class="text-sm text-gray-600">
                                    Amount :
                                    <div class="text-gray-600">{{ QuantityTotalOrPrices('price',formData.details)+QuantityTotalOrPrices('fees',formData.fees)  }} <sup v-if="formData.warehouse">{{formData.warehouse.currency}}</sup></div>
                                </div>
                                <div class="text-sm text-gray-600">
                                    Amount in MAD:
                                    <div class="text-gray-600">{{ QuantityTotalOrPrices('priceMAD',formData.details)+QuantityTotalOrPrices('feesMAD',formData.fees)  }} <sup>MAD</sup></div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                    <!--******************** Customer information ********************-->
                    <div class="flex justify-between shadow w-full h-auto p-4 my-1 rounded-lg border-gray-100">
                        <div class="flex w-full">
                            <span class="rounded-full h-12 w-12 bg-gray-100">
                                <i class="material-icons p-3 text-green-400">person</i>
                            </span>
                            <div class="ml-5 w-full">
                                <div class="text-base mt-2 mb-2 font-semibold text-gray-700">
                                    Provider Information
                                </div>
                                <div class="text-sm font-light text-gray-700">
                                    <i class="material-icons text-gray-400">person</i>
                                    <span
                                        class="justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700">
                                        {{ formData.provider.fournisseur }}
                                    </span>
                                </div>
                                <div class="text-sm font-light text-gray-700">
                                    <i class="material-icons text-gray-400">directions_car</i>
                                    <span
                                        class="justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700">
                                        {{ formData.provider.transitaire }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex pr-5 pl-5 mt-6">
                    <div class="flex justify-between w-full h-auto p-4 rounded-lg border-gray-100">
                        <div class="flex w-full">
                            <span class="rounded-full h-12 w-12 bg-gray-100">
                                <i class="material-icons p-3 text-green-400">local_mall</i>
                            </span>
                            <div class="ml-1 w-full p-2">
                                <div class="text-base font-semibold text-gray-700">
                                    Products Sourcing
                                </div>

                                <div class="w-full mt-4">
                                    <table class="min-w-full w-full">
                                        <thead class="text-white">
                                            <tr class="bg-green-400 flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
                                                <th class="p-2 justify-center">Image</th>
                                                <th class="p-2 justify-center">Name</th>
                                                <th class="p-2 justify-center">Quantity</th>
                                                 <th class="p-2 justify-center">Price (MAD)</th>
                                                 <th class="p-2 justify-center">Price <span v-if="formData.warehouse">({{formData.warehouse.currency}})</span></th>
                                            </tr>
                                        </thead>
                                        <tbody class="flex-1 sm:flex-none">
                                            <tr v-for="it in formData.details" :key="it._id" class="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
                                                <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2">
                                                    <div> <img :alt="it.product.name" :src="it.product.picture" class="h-16 w-16 rounded-md mx-auto" /> </div>
                                                </td>
                                                <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"><b>{{ it.product.name }}</b></td>
                                                <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate">
                                                    {{ it.quantity }}
                                                </td>
                                                 <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate">
                                                    {{ it.priceDH }}
                                                </td>
                                                 <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate">
                                                    {{ it.price }}
                                                </td>
                                            </tr>
                                        </tbody>
                                   </table>
                                </div>
                                  <div v-if="formData.fees && formData.fees.length>0" class="w-full">
                                        <h4 class="other-fees">Other fees</h4>
                                        <table style="display: inline-table;"
                                            class="w-full flex flex-row flex-no-wrap sm:bg-white rounded-md overflow-hidden sm:shadow-xs">
                                            <thead class="text-white">
                                                <tr
                                                    class="bg-green-400 flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
                                                    <th class="p-2 justify-center">Fees name</th>
                                                    <th class="p-2 justify-center">Price (MAD)</th>
                                                    <th class="p-2 justify-center">Price <span v-if="formData.warehouse">({{formData.warehouse.currency}})</span></th>
                                                </tr>
                                            </thead>
                                            <tbody class="flex-1 sm:flex-none">
                                            <tr v-for="it in formData.fees" :key="it._id" class="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
                                               
                                                <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"><b>{{ it.name }}</b></td>
                                                 <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate">
                                                    {{ it.priceDH }}
                                                </td>
                                                 <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate">
                                                    {{ it.price }}
                                                </td>
                                            </tr>
                                        </tbody>
                                        </table>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>

                           <!-- **************/Shipping info****************-->
        <div  v-if="$f.getAccessPermission(currentUser, 'sourcingsHistories', 'read')" class="flex pr-5 pl-5 mt-6">
          <div class="flex justify-between w-full h-auto p-4 rounded-lg border-gray-100" >
            <div class="flex w-full">
              <div class="ml-1 w-full p-2">
                <!--table shipping historie -->
                   <div-table :config="configList" :data="sourcingsHistories"  :idWharhouse="idWharhouse" :currentUser="currentUser" :loading="loading" :paginate="paginate"  @paginatation="paginatation"></div-table>
                <!--/ table shipping histories -->
              </div>  
            </div> 
          </div>
        </div> 
            </div>
        </div>
    </div>
</template>
<script>
import config from './configHistories'
export default {
        props: {
            currentUser: { type: Object},
            idWharhouse: { type: Object}
        },
        data() {
            return {
                configList: config.list,
                loading:false,
                sourcingsHistories:[],
                formData: {
                    seller:{},
                    provider: {},
                    details: [],
                },
                show_modal: false,
                content: [],
                 paginate: {
                    total: 0,
                    currentpage: 1,
                    lastpage: 1,
                    per_page: 0,
                },
            };
        },
        async mounted() {

            console.log('ook 11')
            if (this.$route.params.id) {
                console.log('ook 22',this.$route.params.id)
                const data = await this.$server.get("sourcings", {
                    id: this.$route.params.id,
                });
                this.formData = data.content;
                console.log('formData sourcing---', this.formData)
            }
            if (this.$f.getAccessPermission(this.currentUser, 'sourcingsHistories', 'read'))  await this.getSourcingsHistories();
        },
       
        computed: {
            formattedDateExpeded() {
                return this.$moment(this.formData.date).format('yyyy-MM-DD HH:mm');
            },
             formattedDateReception() {
                return this.$moment(this.formData.arrivalDate).format('yyyy-MM-DD HH:mm');
            },
            
        },
        methods: {
             RouterBack: function() {
                this.$router.back();
                },
            async getUser() {
                const res = await this.$me;
                this.currentUser = res.content;
            },
            async getSourcingsHistories() {
                const res = await this.$server.search("sourcingsHistories", {sourcing:this.$route.params.id});
                if (res.content.results) {
                    this.sourcingsHistories = res.content.results;
                    this.sourcingsHistories.total = res.content.total;
                    this.sourcingsHistories.lastpage = res.content.last_page;
                    this.sourcingsHistories.per_page = res.content.per_page;
                    this.sourcingsHistories.currentpage = res.content.current_page;
                } else this.sourcingsHistories = [];
            },
            async paginatation(direction){
                var page=0;
                if(direction == "next"){
                    page=++this.paginate.currentpage;
                }else{
                    page=--this.paginate.currentpage;
                }
                const filters = {
                    page: page,
                    limit: this.limit,
                };
                this.getSourcingsHistories(filters);
            },
            QuantityTotalOrPrices(type,data){
                var value=0;
                for (let i in data) {
                    if(type=='qte') value+=data[i].quantity;
                    else if(type=='fees') value+=data[i].price;
                    else if(type=='feesMAD') value+=data[i].priceDH;
                    else if(type=='priceMAD')  value+=(parseFloat(data[i].priceDH)*parseFloat(data[i].quantity));
                    else value+=(parseFloat(data[i].price)*parseFloat(data[i].quantity));
                    
                }
                return value;
             },
            /*******************************************Modal (View) update Chapping address and Status Order ************************************/
            ShowPopUpInformation(index) {
                let modal_id = "modal_" + index;
                this.$refs[modal_id][0].showModal();
            },
            async closePopUpInformation(index) {
                let modal_id = "modal_" + index;
                this.$refs[modal_id][0].close();
            },

            /**************************Fin Modal ****************************** */
        },
    };
</script>

<style>
    /* animation for vue transition tag */
    dialog[open] {
        animation: appear 0.15s cubic-bezier(0, 1.8, 1, 1.8);
    }

    dialog::backdrop {
        background: linear-gradient(45deg, rgba(0, 0, 0, 0.5), rgba(54, 54, 54, 0.5));
        backdrop-filter: blur(3px);
    }

    @keyframes appear {
        from {
            opacity: 0;
            transform: translateX(-3rem);
        }

        to {
            opacity: 1;
            transform: translateX(0);
        }
    }
</style>