export default {
    list: {
        title: "Sourcing Histories",
        name: 'sourcingsHistories',
        icone: "history",
        class: "flex-1 mt-1 px-3 items-center",
        options: {
            defaultPageSize: 5,
            actions: {
                isActive: false,
                name: "Actions",
                buttons: [{
                    text: "",
                    icon: "",
                    link: '',
                    color: 'primary'
                }],
            }
        },
        columns: [{
                name: "Date",
                elements: [{
                    items: [{
                        class: "",
                        date: true,
                        name: "date",
                        type: "text",
                    }, ],
                }, ]
            },
            {
                name: "User",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        name: "user",
                        elements: [{
                            items: [{
                                name: "fullName",
                                class: "font-bold",
                                icone: "contacts"
                            }]
                        }]
                    }],
                }, ]
            },
            {
                name: "Status",
                class: "",
                elements: [{
                    items: [{
                        class: "border capitalize rounded-full text-gray-600 h-8 text-xs px-8 bg-white hover:border-gray-500 focus:outline-none appearance-none",
                        name: "status",
                        options: (item) => {
                            switch (item) {
                                case 'pending':
                                    return "color: rgb(233 147 19); border-color: rgb(233 147 19);"
                                    break;
                                case 'accepted':
                                    return "color:rgb(8, 194, 226); border-color: rgb(8, 194, 226);"
                                    break;
                                case 'effectue':
                                    return "color:rgb(58 217 18); border-color: rgb(58 217 18);"
                                    break;
                                    case 'processed':
                                        return "color:rgb(18 23 217); border-color: rgb(18 23 217);"
                                        break;
                                case 'rejected':
                                    return "color: rgb(255, 5, 5); border-color: rgb(255, 5, 5);"
                                    break;
                            }
                        },
                    }],
                }, ]
            },
         {
            name: "Comment",
            elements: [{
                items: [{
                    class: "",
                    name: "comment",
                    type: "text",
                }, ],
            }, ]
        },
        ],
        actions: {
            name: "Actions",
            width: "10px",
            class: "justify-end",
            elements: [{
                icone: "delete",
                color: "danger",
                role: true,
                key: 'delete',
                tooltip: "Delete",
            }],
        },

    },
}
